import React from "react"
import {inject, observer} from "mobx-react";
import Helmet from "react-helmet"

import Layout from "../components/layout"
import colors from '../components/colors';
import policy_en from '../../content/en_privacy.json'
import policy_de from '../../content/de_privacy.json'
import ReactMarkdown from "react-markdown"

class Privacy extends React.Component {
    

    render() {
        
        let policy = this.props.store.language ==='en'? policy_en:policy_de
        return(
        <Layout>
        <Helmet>
          <title>{'LT | Privacy'}</title>
          </Helmet>
        <div style={{minHeight:'80vh', width:'100%',maxWidth:'980px',margin:'0px auto', color:colors.textLightgrey,backgroundColor: colors.darkblue, padding:'5vw'}}>
        <h1 style={{paddingBottom:'5vh'}}>{this.props.store.language==='en'?'Privacy Policy':'Datenschutz'}</h1>
        
                <div style={{paddingLeft:'3vw'}}>
                <ReactMarkdown parserOptions={{ commonmark: true }} escapeHtml={false} source={policy.title} />
                </div>
            
        </div>


        </Layout>
        )
    }

}
export default inject('store')(observer(Privacy))